
.inputTextForm {
    border-radius: 10px;
    border: 1px solid #e1e1e1 ;
    background-color: #fff;
  }
  
  
  .inputTextForm::placeholder { 
    color: gray;
  margin-left: 5%; }
  
  .inputTextForm:focus {
    border-radius: 10px;
    border: 2px solid orange;
  }

  

  
  
  
  
  
  