.storybook-button-link{
  color: #ea7526;
}

.storybook-button {
  font-family: 'Nunito Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: 500;
  border: 0;
  border-radius: 3em;
  cursor: pointer;
  display: inline-block;
  line-height: 1;
  margin-top: 5%;
  text-align: start;
  width: 100%;
}
.storybook-button-button {
 background-color: #ea7526;
 color: #FFF;
}

.storybook-button--primary {
  color: white;
  background-color: #1ea7fd;
}
.storybook-button--secondary {
  color: #333;
  background-color: transparent;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 0px 1px inset;
}
.storybook-button--small {
  font-size: 12px;
  padding: 10px 16px;
}
.storybook-button--medium {
  font-size: 14px;
  padding: 11px 20px;
}
.storybook-button--large {
  font-size: 16px;
  padding: 12px 24px;
}
/* ******** BUTTON LEFT********************* */
.storybook-button-left {
  font-family: 'Nunito Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: 500;
  border: 0;
  border-radius: 3em;
  cursor: pointer;
  display: inline-block;
  line-height: 1;
  margin-top: 5%;
  text-align: end;
  width: 100%;
}

.storybook-button-button-left {
  background-color: #FFF;
  color: #ea7526;
  border: 2px solid #ea7526;
 }



/* *** Icon ****** */
.iconButton{

  color: #e1e1e1;
  float: left;
  text-align: end;
  margin-top: -15px;
  margin-left: 95%;
  position: relative;
  border: 1px solid #FFF;
  border-radius: 50%;

  @media (max-width: 425px) {
    color: #e1e1e1;
    float: left;

    position: relative;
  }
}

.iconButtonLeft{

  color: #ea7526;
  float: left;
  text-align: end;
  margin-top: -2px;
margin-left: -10px;
  position: relative;
  border: 1px solid #ea7526;
  border-radius: 50%;

  @media (max-width: 425px) {
    color: #ea7526;
    float: left;
    position: relative;
  }
}
